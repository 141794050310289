import React, { useState } from "react";
import { GoogleMap, withScriptjs, withGoogleMap, Marker, InfoWindow } from "react-google-maps";

import img from "../../assets/images/brewhouse_ascenda.png";

const Map = () => {
  const [infoOpen, setInfoOpen] = useState(false);

  return (
    <GoogleMap defaultZoom={17} defaultCenter={{ lat: 57.700574, lng: 11.99368 }}>
      <Marker position={{ lat: 57.70024, lng: 11.99368 }} onClick={() => setInfoOpen(true)} />
      {infoOpen && (
        <InfoWindow onCloseClick={() => setInfoOpen(false)} position={{ lat: 57.70024, lng: 11.99368 }}>
          <div style={{ width: "100%", height: "100%", color: "black" }}>
            <h2>Ascenda AB</h2>
            {/* 
            <p>We are ascenda :)</p><br/> */}
            <img alt="ascenda office" src={img} style={{ height: "100%", width: "100%" }} />
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
};

const WrappedMap = withScriptjs(withGoogleMap(Map));

export default WrappedMap;
