import React, { useEffect } from "react";
import "./home.scss";

import Hero from "../../components/hero/Hero";
import About from "../../components/about/About";
import News from "../../components/news/News";
import News2 from "../../components/newNews/News2";
import AreasLevel1 from "../../components/areasLevel1/AreasLevel1";

import Instagram from "../../assets/icons/social media/instagram.svg";
import LinkedIn from "../../assets/icons/social media/linkedin.svg";

const Home = (props) => {
  const setPosition = (index) => {
    for (var i = 0; i < props.areas.length; i += 4) {
      if (index === i || index === i + 1) {
        return true;
      }
    }
  };

  const sortItemsAlphabeticalOrder = (arr) => {
    arr.sort((a, b) => a.fields.title.localeCompare(b.fields.title));
  };

  useEffect(() => {
    sortItemsAlphabeticalOrder(props.areas);
  }, [props.areas]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div id="home-container">
      <Hero
        slogan={props.hero.map((i) => i.fields.slogan)}
        images={props.hero.map((i) => i.fields.backgroundImages)}
        placeholder={props.hero.map((i) => i.fields.placeholder)}
        imageTimer={props.hero.map((i) => i.fields.imageTimer)}
      />

      {props.news.length !== 0 ? (
        <div id="news">
          <div className="news-section">
            {props.news.slice(0, 3).map((i) => (
              /*             <News
              key={i.sys.id}
              video={i.fields.media}
              headline={i.fields.headline}
              description={i.fields.description}
              images={i.fields.images}
              image={i.fields.bigImage}
            /> */
              <News2
                key={i.sys.id}
                tag={i.fields.tagText}
                tagColor={i.fields.tagColor}
                video={i.fields.media}
                headline={i.fields.headline}
                description={i.fields.description}
                images={i.fields.images}
                image={i.fields.bigImage}
              />
            ))}
          </div>
        </div>
      ) : (
        <div />
      )}

      <div id="about">
        <h1 id="about-header">Ascenda</h1>
        {props.about.map((i) => (
          <About
            key={i.fields.title}
            title={i.fields.title}
            desc={i.fields.description}
            image={i.fields.image.fields.file.url}
            left={i.fields.leftAlignImage}
          />
        ))}
      </div>

      <div id="area-title">
        <h1>{props.lang === "sv" ? "Våra Erbjudanden" : "Our Services"}</h1>
      </div>
      <div id="areas">
        {props.areas.map((i, index) => (
          <AreasLevel1
            key={i.sys.id}
            id={i.fields.title}
            title={i.fields.title}
            desc={i.fields.shortDescription}
            icon={i.fields.icon.fields.file.url}
            position={setPosition(index)}
            ribbonTitle={i.fields.ribbonTextTitle}
            ribbonSubTitle={i.fields.ribbonTextSubTitle}
            ribbonImage={i.fields.ribbonTextImage !== undefined ? i.fields.ribbonTextImage.fields.file.url : ""}
            ribbonLink={i.fields.ribbonTextLink}
          />
        ))}
      </div>

      {props.useCases.length !== 0 ? (
        <div id="useCase">
          <h1 id="useCase-header">Customer Cases</h1>
          <div className="useCase-section">
            {props.useCases.map((i) => (
              <News2
                key={i.sys.id}
                headline={i.fields.headline}
                description={i.fields.description}
                image={i.fields.image}
                tag={i.fields.tagText}
                tagColor={i.fields.tagColor}
              />
            ))}
          </div>
        </div>
      ) : (
        <div />
      )}
    </div>
  );
};

export default Home;
