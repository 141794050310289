import React, { useState, useEffect, Suspense } from "react";
import "./App.scss";

import { Route, Switch } from "react-router-dom";
import Nav from "./nav/Nav";
import Home from "./../pages/home/home";

import Footer from "./footer/Footer";

import client from "../services/contentfulService";
import ReactGA from "react-ga";
import UCNP from "../pages/useCase-newsPage/UCNP";

function App() {
  const [data, setData] = useState([]);
  const [hero, setHero] = useState([]);
  const [news, setNews] = useState([]);
  const [about, setAbout] = useState([]);
  const [areas, setAreas] = useState([]);
  const [useCases, setUseCases] = useState([]);
  const [partners, setPartners] = useState([]);
  const [language, setLanguage] = useState("sv");

  //contentful
  useEffect(() => {
    client
      .getEntries()
      .then((entries) => {
        setData(entries.items);
      })
      .catch((err) => console.log(err));
    client
      .getEntries({ content_type: "area", locale: language })
      .then((entries) => {
        setAreas(entries.items);
      })
      .catch((err) => console.log(err));
    client
      .getEntries({ content_type: "title" }) // hero
      .then((entries) => {
        setHero(entries.items);
      })
      .catch((err) => console.log(err));
    client
      .getEntries({ content_type: "about", locale: language })
      .then((entries) => {
        setAbout(entries.items);
      })
      .catch((err) => console.log(err));
    client
      .getEntries({ content_type: "partner" })
      .then((entries) => {
        setPartners(entries.items);
      })
      .catch((err) => console.log(err));
    client
      .getEntries({ content_type: "news", locale: language })
      .then((entries) => {
        setNews(entries.items);
      })
      .catch((err) => console.log(err));
    client
      .getEntries({ content_type: "useCase", locale: language })
      .then((entries) => {
        setUseCases(entries.items);
      })
      .catch((err) => console.log(err));
  }, [language]);

  //url redirect
  useEffect(() => {
    switch (window.location.href) {
      case "https://powerbi.se":
      case "http://powerbi.se":
        window.location.replace("https://ascenda.se/powerbi");
        break;

      default:
        break;
    }
  }, []);

  //google analytics
  useEffect(() => {
    ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
  }, []);

  return (
    <div className="App">
      <div id="nav">
        <Nav areas={areas} useCases={useCases} langSv={() => setLanguage("sv")} langEn={() => setLanguage("en-US")} lang={language} />
      </div>

      <Switch>
        <Route
          path="/"
          component={() => (
            <Home data={data} hero={hero} areas={areas} about={about} news={news} useCases={useCases} partners={partners} lang={language} />
          )}
        />
      </Switch>

      <Suspense fallback={<div />}>
        <div id="contact">
          <Footer
            email={data.map((i) => i.fields.email)}
            address={data.map((i) => i.fields.address)}
            postnummer={data.map((i) => i.fields.postnummer)}
            phone={data.map((i) => i.fields.phone)}
            box={data.map((i) => i.fields.box)}
          />
        </div>
      </Suspense>
    </div>
  );
}

export default App;
