import React, { useEffect, useRef } from "react";
import "./Hero.scss";

const Hero = (props) => {
  const ref = useRef();

  var nextImageDelay = 30000;
  let currentImageCounter = 0;

  const nextImage = () => {
    ref.current[currentImageCounter].style.opacity = 0;
    currentImageCounter = (currentImageCounter + 2) % ref.current.length;
    ref.current[currentImageCounter].style.opacity = 1;
  };

  setInterval(nextImage, nextImageDelay);

  useEffect(() => {
    ref.current = document.querySelectorAll(".hero-slideshow img");
    nextImage();
  });

  return (
    <div id="hero">
      <div className="hero-slideshow">
        {props.images.length !== 0 ? (
          props.images[0].map((i) => <img key={i.sys.id} alt="" src={i.fields.file.url} />)
        ) : (
          <img alt="" src="" />
        )}
      </div>
      <p id="slogan">{props.slogan}</p>
    </div>
  );
};

export default Hero;
