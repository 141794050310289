import React from "react";
import "./Footer.scss";

import Logo from "../../assets/icons/ascenda logo white.webp";
import Instagram from "../../assets/icons/social media/instagram.svg";
import LinkedIn from "../../assets/icons/social media/linkedin.svg";
import Map from "../map/Map";

const Footer = (props) => {
  return (
    <div id="footer-container">
      <div id="footer-content">
        <img id="footer-logo" alt="logo" src={Logo} />

        <div id="footer-address">
          <p>{props.address}</p>
          <p>{props.postnummer}</p>
          <p>{props.box}</p>
        </div>

        <div id="footer-contact">
          <a href="mailto:info@ascenda.se">{props.email}</a>
          <p>{props.phone}</p>
        </div>

        <div id="footer-media">
          <a href="https://www.linkedin.com/company/ascenda-ab/" target="_blank" rel="noopener noreferrer">
            <img loading="lazy" alt="" src={LinkedIn} />
          </a>
          <a href="https://www.instagram.com/ascendaab/" target="_blank" rel="noopener noreferrer">
            <img loading="lazy" alt="" src={Instagram} />
          </a>
        </div>

        <div id="footer-map">
          <Map
            googleMapURL={`https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`}
            loadingElement={<div style={{ height: "100%" }} />}
            containerElement={<div style={{ height: "100%" }} />}
            mapElement={<div style={{ height: "100%" }} />}
          />
        </div>

        <div className="line" />
        <p id="copyright">@Ascenda 2020</p>
      </div>
    </div>
  );
};

export default Footer;
