import React from "react";
import "./News2.scss";

import marked from "marked";

import placeholder from "../../assets/images/news-placeholder-img.webp";

const News2 = (props) => {
  return (
    <div  className="news2-container">
      <div className="news2-img" style={{backgroundImage: props.image !== undefined ? `url(${props.image.fields.file.url})` : `url(${placeholder})`, backgroundSize: "cover"}}>
        {props.tag !== undefined ? <div className="tag" style={{backgroundColor: props.tagColor}}>{props.tag}</div> : ""}
      </div>
      <div className="news2-text">
        <h1>{props.headline}</h1>
        <p dangerouslySetInnerHTML={{ __html: marked(props.description ? props.description : "") }} />
      </div>
      <div className="line" />
      <p className="readMore" href="/test" /* target="_blank" */ rel="noopener noreferrer">
        LÄS MER
      </p>
      
      <div id={props.headline} className="hiddenTest"/>
    </div>
  );
};

export default News2;
